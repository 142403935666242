<template>
  <div id="single-view">
    <div class="breadcrumbs">
      <a
        href="#"
        title="wizard products"
        @click="goBack()"
        class="single-product-back-link"
      >
        <h3><i class="fa fa-arrow-left"></i> BACK TO PRODUCTS</h3>
      </a>
    </div>
    <div class="container">
      <main id="maincontent" class="row page-main single-product-wrapper">
        <a id="contentarea" tabindex="-1"></a>
        <div class="columns col2-layout">
          <div class="container">
            <div class="product-info-main">
              <h2 class="product-title page-title">
                {{ sproduct.productname }}
              </h2>
              <div class="product-info-price">
                <div
                  class="price-box price-final_price"
                  data-role="priceBox"
                  data-product-id="2070"
                  data-price-box="product-id-2070"
                >
                  <span class="price"
                    >{{ sproduct.price["@currency"] }}
                    {{ sproduct.price["#text"] }}</span
                  >
                </div>
              </div>
              <div class="product attribute overview">
                <div class="value" itemprop="description">
                  <p class="description">
                    {{ description }}
                  </p>
                  <span
                    class="toggleButton"
                    @click="toggleShowDescription()"
                    v-if="showDescriptionButton"
                    >{{ showDescription ? "Show less" : "Show more" }}</span
                  >
                </div>
              </div>
              <div class="product-add-form">
                <form
                  data-product-sku="MT-714258"
                  action=""
                  method="post"
                  id="product_addtocart_form"
                  enctype="multipart/form-data"
                  novalidate="novalidate"
                >
                  <input type="hidden" name="product" value="2070" />
                  <input
                    type="hidden"
                    name="selected_configurable_option"
                    value=""
                  />
                  <input
                    type="hidden"
                    name="related_product"
                    id="related-products-field"
                    value=""
                  />
                  <input type="hidden" name="item" value="2070" />
                  <input
                    name="form_key"
                    type="hidden"
                    value="mO6pMQzYF9fAiJc1"
                  />
                  <div class="product-options-bottom">
                    <div class="box-tocart">
                      <div class="fieldset">
                        <div class="actions">
                          <a
                            v-if="pet_name != null"
                            class="action primary tocart"
                            id="product-addtocart-button"
                            @click="saveClickstreamData(sproduct.linkurl)"
                            style="height:40px;line-height:40px"
                          >
                            <p>Buy Now</p>
                          </a>
                          <a
                            v-else
                            class="action primary tocart"
                            id="product-addtocart-button"
                            @click="saveClickstreamData(sproduct.linkurl)"
                            style="height:40px;line-height:40px"
                          >
                            <p>Buy Now</p>
                          </a>
                          <div
                            id="instant-purchase"
                            data-bind="scope:'instant-purchase'"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="product-social-links">
                <div
                  id="singleProductButtons"
                  class="product-addto-links"
                  data-role="add-to-links"
                >
                  <a
                    data-post=""
                    @click="savetoFavourite(sproduct)"
                    class="action towishlist btn-action link-wishlist"
                    data-action="add-to-favourite"
                    :title="
                      user_contact_name
                        ? `Add to Favourite for ${user_contact_name}`
                        : 'Add to Favourite'
                    "
                  >
                    <span>Add to Favourite</span>
                  </a>
                  <a
                    title="Add to Compare"
                    data-post=""
                    data-role="add-to-links"
                    @click="compareProduct(sproduct)"
                    class="action tocompare"
                  >
                    <span>Add to Compare</span>
                  </a>
                  <a
                    v-if="this.occasion_id == null"
                    @click="selectOccasion(sproduct)"
                    class="action btn-action link-wishlist"
                    data-action="add-to-wishlist"
                    :title="
                      user_contact_name
                        ? `Add to Wish List for ${user_contact_name}`
                        : 'Add to Wish List'
                    "
                  >
                    <img
                      style="width: 50% !important"
                      src="@/assets/site/images/wishlist.png"
                      alt=""
                    />
                  </a>
                  <a
                    v-else
                    @click="savetoWishlistProduct(sproduct)"
                    class="action btn-action link-wishlist"
                    data-action="add-to-wishlist"
                    :title="
                      user_contact_name
                        ? `Add to Wish List for ${user_contact_name}`
                        : 'Add to Wish List'
                    "
                  >
                    <img
                      style="width: 50% !important"
                      src="@/assets/site/images/wishlist.png"
                      alt=""
                  /></a>
                  <button
                    v-if="pet_name"
                    @click="savegift(sproduct)"
                    class="for-user-button"
                  >
                    Save For {{ pet_name }}
                  </button>
                  <button
                    v-if="user_contact_name"
                    @click="savegift(sproduct)"
                    class="for-user-button"
                  >
                    Save For {{ user_contact_name }}
                  </button>
                </div>
              </div>
              <div id="addthis_wrap">
                <div class="addthis_inline_share_toolbox"></div>
              </div>
            </div>
            <div class="product media">
              <a id="gallery-prev-area" tabindex="-1"></a>
              <div class="action-skip-wrapper">
                <a
                  class="action skip gallery-next-area"
                  href="#gallery-next-area"
                >
                  <span> Skip to the end of the images gallery </span>
                </a>
              </div>
              <div
                class="gallery-placeholder"
                data-gallery-role="gallery-placeholder"
                style=""
              >
                <div class="fotorama--hidden"></div>
                <div
                  class="fotorama-item fotorama fotorama1611137713268"
                  data-gallery-role="gallery"
                >
                  <div
                    data-gallery-role="fotorama__focusable-start"
                    tabindex="-1"
                  ></div>
                  <div
                    class="fotorama__wrap fotorama__wrap--css3 fotorama__wrap--slide fotorama__wrap--toggle-arrows fotorama__wrap--no-controls"
                    style="min-width: 0px; max-width: 100%"
                  >
                    <div
                      class="fotorama__stage"
                      data-fotorama-stage="fotorama__stage"
                      style="height: 100%; width: 100%"
                    >
                      <div
                        class="fotorama__fullscreen-icon"
                        data-gallery-role="fotorama__fullscreen-icon"
                        tabindex="0"
                        aria-label="Exit fullscreen"
                        role="button"
                      ></div>
                      <div
                        class="fotorama__arr fotorama__arr--prev"
                        tabindex="0"
                        role="button"
                        aria-label="Previous"
                        data-gallery-role="arrow"
                      >
                        <div class="fotorama__arr__arr"></div>
                      </div>
                      <div
                        class="fotorama__stage__shaft fotorama__grab"
                        tabindex="0"
                        data-gallery-role="stage-shaft"
                      >
                        <div class="fotorama__stage__frame">
                          <span v-if="sproduct.imageurl">
                            <img
                              :src="sproduct.imageurl"
                              alt=""
                              @error="() => (sproduct.imageurl = null)"
                              aria-labelledby="labelledby1611137713287"
                              class="fotorama__img magnify-opaque magnify-opaque single_product"
                              aria-hidden="false"
                            />
                          </span>
                          <span v-else>
                            <img
                              src="@/assets/site/images/noimage.jpeg"
                              alt=""
                              aria-labelledby="labelledby1611137713287"
                              class="fotorama__img magnify-opaque magnify-opaque single_product"
                              aria-hidden="false"
                            />
                          </span>

                          <div class="fotorama__caption" aria-hidden="true">
                            <div
                              class="fotorama__caption__wrap"
                              id="labelledby1611137713287"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    data-gallery-role="fotorama__focusable-end"
                    tabindex="-1"
                  ></div>
                </div>
              </div>
              <!--Fix for jumping content. Loader must be the same size as gallery.-->
            </div>
          </div>
        </div>
      </main>
    </div>
    <div class="conatiner mb-5 px-5 mx-5">
      <div class="col-xs-3 col-sm-3 col-md-3" v-for="coupon in coupons">
        <div class="coupon">
          <center>
            <span>
              <h4>
                {{ coupon.promotiontypes.promotiontype[0]["#text"] }}
              </h4> </span
            ><br />
          </center>
          <span class="coupon-head">
            <h4 class="d-flex align-self-center">
              {{ coupon.offerdescription }}
            </h4> </span
          ><br />
          <span class="pull-left coupon-date"
            >Start Date:{{ coupon.offerstartdate }}</span
          >
          <span class="pull-right coupon-date"
            >End Date:{{ coupon.offerenddate }}</span
          ><br />
          <center>
            <a
              v-if="userData"
              :href="coupon.clickurl"
              target="_blank"
              class="action primary tocart mt-3"
              id="product-addtocart-button"
              style="height:40px;line-height:40px"
            >
              <p>Buy Now</p>
            </a>
            <button
              v-else
              @click="openModal"
              class="action primary tocart mt-3"
              id="product-addtocart-button"
              style="height:40px;line-height:40px"
            >
              <p>Buy Now</p>
            </button>
          </center>
        </div>
      </div>
    </div>
    <div>
      <occasion-modal
        v-bind:type="type"
        v-on:savetoWishlist="savetoWishlist"
      ></occasion-modal>
    </div>
    <div
      id="myModal"
      ref="modal"
      class="modal fade bd-example-modal-lg"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content p-5 custom-modal">
          <div class="row d-flex justify-content-end">
            <div class="col-1" @click="closeloginModal">
              <a href="#">
                <svg
                  fill="#ffc000"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 50 50"
                  width="40px"
                  height="40px"
                >
                  <path
                    d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 32.990234 15.986328 A 1.0001 1.0001 0 0 0 32.292969 16.292969 L 25 23.585938 L 17.707031 16.292969 A 1.0001 1.0001 0 0 0 16.990234 15.990234 A 1.0001 1.0001 0 0 0 16.292969 17.707031 L 23.585938 25 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 25 26.414062 L 32.292969 33.707031 A 1.0001 1.0001 0 1 0 33.707031 32.292969 L 26.414062 25 L 33.707031 17.707031 A 1.0001 1.0001 0 0 0 32.990234 15.986328 z"
                  />
                </svg>
              </a>
            </div>
          </div>

          <div class="row text-center">
            <div class="col-md-6 my-3">
              <img
                src="@/assets/site/images/new/banner.jpg"
                class="img-fluid"
                alt=""
              />
              <div class="row mt-4 d-flex justify-content-center">
                <div class="col-2">
                  <div
                    class="
                          category-holder1
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                  >
                    <img
                      src="@/assets/site/images/new/laptop.png"
                      width="40"
                      height="40"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-2">
                  <div
                    class="
                          category-holder1
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                  >
                    <img
                      src="@/assets/site/images/new/shoes.jpg"
                      width="40"
                      height="40"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-2">
                  <div
                    class="
                          category-holder1
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                  >
                    <img
                      src="@/assets/site/images/new/pendant.jpg"
                      width="40"
                      height="40"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-2">
                  <div
                    class="
                          category-holder1
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                  >
                    <img
                      src="@/assets/site/images/new/smart-watch.webp"
                      width="40"
                      height="40"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-2">
                  <div
                    class="
                          category-holder1
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                  >
                    <img
                      src="@/assets/site/images/new/watch.webp"
                      width="40"
                      height="40"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <h3 class="py-5 modal-head">FIND THE PERFECT GIFT</h3>
            </div>
            <div class="col-md-6 my-3">
              <h3>
                <b>Unlock Govava True Power</b>
              </h3>
              <p class="my-4 text-left" style="font-size: 14px">
                Sign up to embark on a unique gifting experience unlike any
                other.
              </p>
              <button class="btn btn-info1 my-4">
                <a href="/login">Sign Up</a>
              </button>
              <ul id="modal_ul" class="my-4 text-left">
                <li>Access to over 8000+ Retail stores</li>
                <li>Valid coupons on almost everyitem instantly</li>
                <li>No more guessing when tryimg to find gifts</li>
                <li>Shopping for gifts for your pets</li>
                <li>Gift-Chat in real time using the app</li>
                <li>Save Gifts for family and friends in Gift-Cart</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OccasionModal from "@/views/site/OccasionModal";

export default {
  name: "SingleProductView",
  metaInfo: {
    title: "Product Detail",
    meta: [
      {
        name: "description",
        content:
          "Explore the detailed view of our product with an intuitive interface. Discover pricing, descriptions, and options to buy or save to your wishlist seamlessly. Enhance your shopping experience today!",
      },
      {
        name: "keywords",
        content:
          "product detail, Vue.js product template, product pricing, product description, buy now button, wishlist feature, add to favorites, image gallery, responsive design, user-friendly interface, product options, share product, add to compare, product reviews, online shopping, e-commerce.",
      },
    ],
  },
  data() {
    return {
      loader: false,
      showDescription: false,
      showDescriptionButton: false,
      description: "",
      errors: [],
      pages: [],
      sproduct: [],
      wizarddata: [],
      user_contact_name: null,
      user_contact_id: null,
      pet_name: null,
      occasion_id: null,
      coupons: [],
      wishlist_product: null,
      type: "",
      wizard_type: null,
      wishlist_type: null,
      pet_id: null,
      userData: null,
    };
  },
  components: {
    OccasionModal,
  },
  created() {
    this.userData = localStorage.getItem("userData");
    window.scrollTo(0, 0);
    this.sproduct = this.$route.query.singleproduct;
    this.wizarddata = this.$store.state.clicked_wizarddata;
    this.user_contact_id = this.$route.query.selected_friend;
    this.user_contact_name = this.$route.query.selected_friend_name;
    this.pet_name = this.$route.query.pet_name;
    this.type = this.$route.query.type;
    this.occasion_id = this.$store.state.occasion_id;
    this.pet_id = this.$store.state.pet_id;
    this.wizard_type = this.$store.state.wizard_type;
    if (this.wizard_type == "Pet") {
      this.wishlist_type = "pet_wishlist";
    } else {
      this.wishlist_type = "user_wishlist";
    }

    const product = this.$route.query.singleproduct;
    const description = product.description.long ?? product.description.short;

    if (!description) return;

    if (description.split(" ").length > 40) {
      this.showDescriptionButton = true;
      this.description = "";

      description.split(" ").forEach((word, index) => {
        if (index < 40) this.description += ` ${word}`;
      });
      this.description += "...";
    } else {
      this.description = description;
    }

    this.getCouponDetails();
  },
  methods: {
    toggleShowDescription() {
      const product = this.$route.query.singleproduct;
      const description = product.description.long ?? product.description.short;

      if (this.description.split(" ").length === 41) {
        this.description = description;
        this.showDescription = true;
      } else {
        this.showDescription = false;
        this.description = "";

        description.split(" ").forEach((word, index) => {
          if (index < 40) this.description += ` ${word}`;
        });
        this.description += "...";
      }
    },
    getCouponDetails() {
      this.errors = [];
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url =
        process.env.VUE_APP_URL + "customer/products/getcouponDetails";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          product_id: this.sproduct.productId,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.coupons = data.response.coupons;
        });
    },
    selectOccasion(product) {
      this.wishlist_product = product;
      this.$bvModal.show("occasion-modal");
    },
    saveClickstreamData(url) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        $("#myModal").modal("show");
      } else {
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.token;
        var user_id = userdata_array.user.id;
        var country_code = localStorage.getItem("countryCode");
        var countryCode = JSON.parse(country_code);

        var fetch_url =
          process.env.VUE_APP_URL +
          "customer/products/saveAffilativeNetwork/" +
          this.sproduct.productId;
        fetch(fetch_url, {
          method: "get",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
        })
          .then((res) => res.json())
          .then((data) => {});

        var fetch_url =
          process.env.VUE_APP_URL + "customer/clickstream/SaveClickDatas";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            user_id: user_id,
            product_id: this.sproduct.productId,
            clickstream_data: this.wizarddata,
            user_action: "AddToCart",
            country_code: countryCode,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.loader = false;
            } else if (data.status === false) {
              this.loader = false;
              this.$swal.fire("Error", "Product Not saved !", "error");
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
        window.open(url, "_blank");
      }
    },
    selectOccasion(product) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        $("#myModal").modal("show");
      } else {
        this.wishlist_product = product;
        this.$bvModal.show("occasion-modal");
      }
    },
    savetoWishlist(occasion) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        $("#myModal").modal("show");
      } else {
        this.occasion_id = occasion.id;
        this.savetoWishlistProduct(this.wishlist_product);
      }
    },
    savetoWishlistProduct(sproduct) {
      this.$bvModal.hide("occasion-modal");
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);

      if (!userdata_array) {
        $("#myModal").modal("show");
      }

      var api_token = userdata_array.token;
      var user_id = userdata_array.user.id;
      var fetch_url = process.env.VUE_APP_URL + "customer/userwishlist";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          product_id: sproduct.productId,
          product: sproduct,
          user_id: user_id,
          wishlist_type: this.wizard_type,
          pet_id: this.pet_id,
          type: this.wishlist_type,
          clickstream_data: this.wizarddata,
          user_action: "AddToWishlist",
          occasion_id: this.occasion_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          if (data.status === true) {
            this.$swal.fire("Good to Go", data.message, "success");
          } else if (data.status === false) {
            this.loader = false;

            this.$swal.fire("Error", data.message, "error");
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          this.$swal("Product", "Error : " + err.message, "error");
        });
      this.wishlist_product = null;
    },
    compareProduct(sproduct) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        $("#myModal").modal("show");
      } else {
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/compare";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: sproduct.productId,
            product: sproduct,
            wishlist_type: this.wizard_type,
            user_id: user_id,
            clickstream_data: this.wizarddata,
            user_action: "compare",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.loader = false;
              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.loader = false;
              this.$swal.fire("Error", data.message, "error");
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    savegift(sproduct) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        $("#myModal").modal("show");
      } else {
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.user.api_token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/savegift";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product: sproduct,
            user_id: user_id,
            product_id: sproduct.productId,
            user_contact_id: this.user_contact_id,
            clickstream_data: this.wizarddata,
            user_action: "SavedForGiftee",
            occasion_id: this.occasion_id,
            pet_id: this.pet_id,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.loader = false;
              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.loader = false;
              this.$swal.fire("Error", data.message, "error");
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
      }
    },
    savetoFavourite(sproduct) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        $("#myModal").modal("show");
      } else {
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.user.token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/favourite";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: sproduct.productId,
            product: sproduct,
            user_id: user_id,
            user_action: "AddToFav",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.loader = false;

              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.$swal.fire("Error", data.message, "error");
            } else {
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
      }
    },
    closeloginModal() {
      $("#myModal").modal("hide");
    },
    openModal() {
      $("#myModal").modal("show");
    },
  },
};
</script>

<style scoped>
#singleProductButtons {
  display: flex;
}

#singleProductButtons button {
  height: initial;
  line-height: initial;
}

.single-product-back-link {
  display: block;
  text-align: left;
  padding-left: 20px;
  font-weight: 500;
  color: #5c5c5c !important;
}

.single-product-back-link:hover {
  text-decoration: none;
  color: #e53600 !important;
}

.single-product-wrapper {
  padding-top: 0 !important;
}

.product-title {
  white-space: initial;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  color: #0b79bf;
}

.description {
  font-size: 16px;
}

.description.toggle {
  max-height: 5000px;
}

.toggleButton {
  cursor: pointer;
  color: #e53600;
  font-weight: 500;
  font-size: 16px;
  text-decoration: underline;
}
</style>
